import React from 'react';
import Layout from '../components/Layout';
import Video from '../components/Video';

const SignLanguage = () => {
  return (
    <Layout pageTitle="Gebärdensprache" pageUrl="gebaerdensprache">
      <section
        className="max-w-screen-lg pr-6 pl-6 m-auto"
        aria-labelledby="page-heading"
      >
        <h1
          id="page-heading"
          className="text-center lg:text-5xl text-2xl font-bold uppercase lg:mb-12 mb-6"
        >
          Gebärdensprache
        </h1>

        <div className="prose lg:prose-xl max-w-full">
          <p>
            An dieser Stelle finden Sie Informationen in Deutscher
            Gebärdensprache.
          </p>
          {/* use "https://www.wieklickstdu.de/videos/" to see videos locally */}
          <Video
            title="Navigation auf unserer Internetseite"
            videoSrc="/videos/210303_Klimaschutztyp_Navigation_1080p.mp4"
            trackSrc="/videos/21Klimaschutztyp_Navigation_ueberarbeitet.vtt"
            poster="/images/Klimaschutz_Navigation_bild.png"
          />
          <Video
            title="Was bietet die Internetseite?"
            videoSrc="/videos/210225_Klimaschutztyp_Inhalt_1080p.mp4"
            trackSrc="/videos/21Klimaschutztyp_Inhalt_ueberarbeitet.vtt"
            poster="/images/Klimaschutz_Inhalt_Bild.png"
          />
          <Video
            title="Erklärung zur Barrierefreiheit"
            videoSrc="/videos/210311_Klimaschutztyp_EzB_1080p.mp4"
            trackSrc="/videos/21Klimaschutztyp_EZB.vtt"
            poster="/images/Klimaschutz_EzB_Bild.png"
          />
        </div>
      </section>
    </Layout>
  );
};

export default SignLanguage;
