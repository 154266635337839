import React from 'react';

const plyrLang = {
  restart: 'Neu starten',
  rewind: 'Zurückspulen {seektime}s',
  play: 'Abspielen',
  pause: 'Pause',
  fastForward: 'Vorwärtsspulen {seektime}s',
  seek: 'Zeit Slider',
  seekLabel: '{currentTime} of {duration}',
  played: 'abgespielt',
  buffered: 'gepuffert',
  currentTime: 'Aktuelle Zeit',
  duration: 'Dauer',
  volume: 'Lautstärke',
  mute: 'Stummschaltung',
  unmute: 'Stummschaltung aufheben',
  enableCaptions: 'Untertitel anschalten',
  disableCaptions: 'Untertitel ausschalten',
  download: 'Download',
  enterFullscreen: 'Vollbildmodus',
  exitFullscreen: 'Vollbildmodus beenden',
  frameTitle: '{title}',
  captions: 'Untertitel',
  settings: 'Einstellungen',
  pip: 'PIP',
  menuBack: 'Zurück',
  speed: 'Geschwindigkeit',
  normal: 'Normal',
  quality: 'Qualität',
  loop: 'Schleife',
  start: 'Start',
  end: 'Ende',
  all: 'Alle',
  reset: 'Neustarten',
  disabled: 'Ausgeblendet',
  enabled: 'eingeblendet',
  advertisement: 'Werbung',
  qualityBadge: {
    2160: '4K',
    1440: 'HD',
    1080: 'HD',
    720: 'HD',
    576: 'SD',
    480: 'SD',
  },
};
const Video = ({ title, text, videoSrc, trackSrc, poster = '' }) => {
  /**
   * Basically, what we're going to do in the useEffect hook is:
   * A) Check that window & document are both defined so the builds work
   * B) Conditionally require the core plyr package if the condition is met
   * C) Look for any of our video classes on the page, then initialize them
   */

  React.useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.js-player')).map(
        p => new Plyr(p, { i18n: plyrLang, loadSprite: false })
      );
    }
  }, []);

  return (
    <div className="lg:mt-24 mt-12">
      {title && (
        <h2 className="text-2xl lg:text-3xl leading-tight font-bold text-center mb-6">
          {title}
        </h2>
      )}
      {text && <p className="lg:text-xl font-medium">{text}</p>}
      <video
        className="js-player no-margin"
        preload="metadata"
        poster={poster}
        data-poster={poster}
        playsInline
        controls
      >
        {/* <source src="/path/to/video.mp4" type="video/mp4" /> */}
        <source src={videoSrc} type="video/mp4" />

        <track
          src={trackSrc}
          srcLang="de"
          label="Deutsche Untertitel"
          kind="subtitles"
          default
        />
      </video>
    </div>
  );
};
export default Video;
